<template>
  <v-alert
    dense
    outlined
    type="info"
    color="blue"
    class="mb-3 px-1"
    v-if="manualExportEntities.length"
  >
    As entidades {{ manualExportEntities.join(', ') }} são de exportação manual
    e devem ser geradas pela área responsável por esses dados (por exemplo,
    Perdas Técnicas).
  </v-alert>
</template>

<script>
import EntidadesBdgdService from '@/services/EntidadesBdgdService';

export default {
  data() {
    return {
      manualExportEntities: []
    };
  },
  mounted() {
    this.getManualExportEntities();
  },
  methods: {
    getManualExportEntities() {
      EntidadesBdgdService.getEntidadesExportacoesManuais()
        .then((response) => (this.manualExportEntities = response.data))
        .catch(() =>
          this.$toast.error('Erro ao buscar as entidades da BDGD.', '', {
            position: 'topRight'
          })
        );
    }
  }
};
</script>
