import APIService from './APIService';

export default {
  getExportacoes() {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get('/exportacoes-bdgds-distribuidoras/index')
        .then((response) => resolve(response.data.data))
        .catch((error) => reject(error));
    });
  },

  getExportacao(id) {
    return APIService.apiCall().get(`/exportacoes-bdgds-distribuidoras/${id}`);
  },

  save(postData) {
    return APIService.apiCall().post(
      '/exportacoes-bdgds-distribuidoras/save',
      JSON.stringify(postData)
    );
  },

  baixarZip(id, config = {}) {
    return APIService.apiCall().get(
      `/exportacoes-bdgds-distribuidoras/baixar-arquivo-resultado/${id}`,
      { responseType: 'blob', ...config }
    );
  }
};
