<template>
  <v-container fluid>
    <v-tab-item
      class="pb-0"
      eager
    >
      <first-tab-content
        :itemsTable="itemsTableFirstTab"
        :selectedItem.sync="selectedItemFirstTab"
        :selectedServer.sync="selectedServerFirstTab"
        :selectedOwner.sync="selectedOwnerFirstTab"
        @stepReleased="
          $emit('step-observer', { step: 0, complete: $event });
          $emit('step-observer', { step: 1, complete: $event });
        "
      />
    </v-tab-item>
    <v-tab-item
      class="pb-0"
      eager
    >
      <second-tab-content
        :records="selectedItemsCount"
        @importEntity:changed="($event) => (importEntity = $event)"
        @month:selected="($event) => (monthSelected = $event)"
        @bdgdVersion:selected="($event) => (bdgdVersionSelected = $event)"
      />
    </v-tab-item>
  </v-container>
</template>

<script>
import FirstTabContent from '@/components/geracao-bdgd/extrair-bdgd/tabs/FirstTabContent.vue';
import SecondTabContent from '@/components/geracao-bdgd/extrair-bdgd/tabs/SecondTabContent.vue';

export default {
  props: {
    items: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    FirstTabContent,
    SecondTabContent
  },
  data() {
    return {
      selectedItemFirstTab: [],
      selectedServerFirstTab: null,
      selectedOwnerFirstTab: 'CADGEO',
      importEntity: false,
      monthSelected: null,
      bdgdVersionSelected: null
    };
  },
  computed: {
    selectedItemsCount() {
      return this.selectedItemFirstTab.length;
    },
    itemsTableFirstTab() {
      return this.$store.getters.getCompanyMandatoryBdgdEntities.map(
        (entity) => ({
          entidade: entity
        })
      );
    }
  },
  methods: {
    exportData() {
      let arrEntidades = [];
      this.selectedItemFirstTab.forEach((item) => {
        arrEntidades.push(item.entidade);
      });
      return {
        entidades: JSON.stringify(arrEntidades),
        servidorId: this.selectedServerFirstTab.id,
        owner: this.selectedOwnerFirstTab,
        importarBdgd: this.importEntity,
        mesBdgd: this.monthSelected,
        versaoBdgd: this.bdgdVersionSelected
      };
    }
  }
};
</script>
