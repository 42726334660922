<template>
  <v-container
    fluid
    tag="section"
  >
    <material-wizard
      title="Nova Extração de BDGD"
      subtitle="Selecione as entidades que irão compor a BDGD"
      v-model="tab"
      :available-steps="tabsLiberadas"
      :tabs="tabs"
      class="mx-auto"
      @click:next="next()"
      @click:prev="back()"
      :loading="loading"
    >
      <exportar-bdgd-distribuidora-wizard-tabs
        ref="exportarBdgdParametros"
        :items="exportacoes"
        @step-observer="updateTabStatus($event)"
      />
    </material-wizard>
  </v-container>
</template>

<script>
import ExportacoesBdgdsDistribuidorasService from '@/services/ExportacoesBdgdsDistribuidorasService';
import ExportarBdgdDistribuidoraWizardTabs from '@/components/geracao-bdgd/extrair-bdgd/ExportarBdgdDistribuidoraWizardTabs';
import MaterialWizard from '@/components/base/MaterialWizard';
import wizardMixins from '@/mixins/wizardMixins';
import routes from '@/store/modules/routes';

export default {
  mixins: [wizardMixins],
  components: {
    ExportarBdgdDistribuidoraWizardTabs,
    MaterialWizard
  },
  data: () => ({
    tabs: ['Entidades', 'Confirmação'],
    loading: false,
    exportacoes: {}
  }),
  methods: {
    save() {
      const data = this.$refs.exportarBdgdParametros.exportData();
      const { importarBdgd, mesBdgd, versaoBdgd } = data;
      if (importarBdgd && (!mesBdgd || !versaoBdgd)) {
        this.$toast.error(
          'Por favor, preencha o mês e versão para a importação da BDGD.',
          '',
          {
            position: 'topRight'
          }
        );
        return;
      }
      this.loading = true;
      ExportacoesBdgdsDistribuidorasService.save(data)
        .then(() => {
          this.$toast.success('Extração de BDGD salva com sucesso.', '', {
            position: 'topRight'
          });
          this.$router.replace({
            name: routes.state.appRoutes['EXTRAIR_BDGD_HISTORICO']
          });
        })
        .catch(() => {
          this.$toast.error('Erro ao salvar a Extração de BDGD.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
