<template>
  <div>
    <v-row class="mx-auto">
      <v-col
        class="pt-1 pb-0"
        cols="12"
      >
        <v-card
          class="my-0"
          id="third-step-exportacao-bdgd-confirm"
        >
          <v-toolbar
            color="primary"
            dark
            dense
            flat
          >
            <v-toolbar-title class="primary text-tabs-exportacao-bdgd">
              <span>Verifique as informações e confirme</span>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="text-tabs-exportacao-bdgd">
            Confirma a extração das entidades selecionadas?
          </v-card-text>
          <v-card-text class="text-tabs-exportacao-bdgd">
            Nº de entidades:
            <b style="color: red">{{ records | parseNumberToIntegerBR }}</b>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mx-auto">
      <v-col cols="12">
        <v-checkbox
          v-model="importEntity"
          label="Adicionar na fila de importação após a extração?"
        />
      </v-col>
    </v-row>
    <v-row
      class="mx-auto"
      v-if="importEntity"
    >
      <v-col cols="12">
        <input-month
          label="Mês dos dados"
          @monthSelected="($event) => (monthSelected = $event)"
        />
      </v-col>
    </v-row>
    <v-row
      class="mx-auto"
      v-if="importEntity"
    >
      <v-col cols="12">
        <input-bdgd-version
          id="fs-versao-importacao-bdgd"
          label="Versão da BDGD"
          :companyId="companyId"
          :month="monthSelected"
          @bdgdVersionSelected="($event) => (bdgdVersionSelected = $event)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import InputMonth from '@/components/general/InputMonth.vue';
import InputBdgdVersion from '@/components/general/InputBdgdVersion.vue';

export default {
  components: {
    InputMonth,
    InputBdgdVersion
  },
  props: {
    records: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      importEntity: false,
      monthSelected: null,
      bdgdVersionSelected: null
    };
  },
  computed: {
    companyId() {
      return this.$store.getters.getCompanyId;
    }
  },
  watch: {
    importEntity() {
      this.$emit('importEntity:changed', this.importEntity);
    },
    monthSelected() {
      this.$emit('month:selected', this.monthSelected);
    },
    bdgdVersionSelected() {
      this.$emit('bdgdVersion:selected', this.bdgdVersionSelected);
    }
  }
};
</script>

<style>
.text-tabs-exportacao-bdgd {
  color: #333 !important;
  font-size: 17px !important;
  font-weight: 300 !important;
}
.text-tabs-exportacao-bdgd span {
  color: #fff !important;
  font-size: 1.125rem !important;
  font-weight: 400 !important;
  line-height: 1.4em !important;
  letter-spacing: 0.0073529412em !important;
}
</style>
