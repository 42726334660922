<template>
  <div>
    <v-row class="mx-auto">
      <v-col
        class="pb-0"
        cols="12"
      >
        <div class="text-center display-1 font-weight-normal mb-6">
          Selecione o servidor origem dos dados
        </div>
        <select-server
          :type="'outlined'"
          @serverSelected="serverSelected"
        />
      </v-col>
    </v-row>
    <v-row class="mx-auto">
      <v-col cols="12">
        <v-text-field
          label="Owner ou schema em que as views estão criadas"
          outlined
          v-model="selectedOwnerLocal"
        />
      </v-col>
    </v-row>
    <v-row class="mx-auto">
      <v-col
        class="pb-0"
        cols="12"
      >
        <div class="text-center display-1 font-weight-normal mb-6">
          Selecione as entidades que serão extraídas
        </div>
        <manual-export-entities-alert />
        <v-data-table
          id="first-step-exportacao-bdgd-table"
          v-model="selectedItemLocal"
          :headers="headers"
          :items="itemsTable"
          :search.sync="search"
          :single-select="false"
          item-key="entidade"
          show-select
          class="elevation-1"
          hide-default-footer
          disable-pagination
          no-results-text="Nenhum registro correspondente encontrado"
        >
          <template v-slot:top>
            <span id="first-step-search">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Procurar"
                hide-details
                single-line
                class="mx-4 mb-3"
              />
            </span>
          </template>
          <template v-slot:[`item.entidade`]="{ item }">
            {{ item.entidade | toUpperCase }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ManualExportEntitiesAlert from '@/components/geracao-bdgd/extrair-bdgd/ManualExportEntitiesAlert.vue';
import SelectServer from '@/components/oracle/servidores-bancos-dados/SelectServer.vue';

export default {
  components: {
    ManualExportEntitiesAlert,
    SelectServer
  },
  props: {
    itemsTable: {
      type: Array,
      default: () => []
    },
    selectedItem: {
      type: Array,
      default: () => []
    },
    selectedServer: {
      type: Object,
      default: () => {}
    },
    selectedOwner: {
      type: String,
      default: 'CADGEO'
    }
  },
  data: () => ({
    search: '',
    headers: [
      {
        text: 'Entidade',
        value: 'entidade'
      }
    ]
  }),
  methods: {
    serverSelected(server) {
      this.selectedServerLocal = server;
      this.stepReleasedIf();
    },
    stepReleasedIf() {
      if (
        this.selectedItemLocal.length &&
        this.selectedServerLocal &&
        this.selectedOwnerLocal
      ) {
        this.$emit('stepReleased', 1);
      } else {
        this.$emit('stepReleased', 0);
      }
    }
  },
  computed: {
    selectedItemLocal: {
      get() {
        return this.selectedItem;
      },
      set(newValue) {
        this.$emit('update:selectedItem', newValue);
      }
    },
    selectedServerLocal: {
      get() {
        return this.selectedServer;
      },
      set(newValue) {
        this.$emit('update:selectedServer', newValue);
      }
    },
    selectedOwnerLocal: {
      get() {
        return this.selectedOwner;
      },
      set(newValue) {
        this.$emit('update:selectedOwner', newValue);
      }
    }
  },
  watch: {
    selectedItem() {
      this.stepReleasedIf();
    },
    selectedServer() {
      this.stepReleasedIf();
    },
    selectedOwner() {
      this.stepReleasedIf();
    }
  }
};
</script>
