<template>
  <div>
    <v-text-field
      v-model="bdgdVersion"
      type="number"
      min="1"
      prepend-icon="mdi-numeric-1-box-multiple-outline"
      :label="label"
      :loading="loadingVariables"
      :disabled="loadingVariables"
    />
  </div>
</template>

<script>
import BdgdVersionsService from '@/services/BdgdVersionsService';

export default {
  name: 'InputBdgdVersion',
  props: {
    label: {
      type: String,
      default: 'Versão da BDGD'
    },
    companyId: {
      type: Number,
      default: 0
    },
    month: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      bdgdVersion: null,
      loadingLocal: false
    };
  },
  computed: {
    data() {
      return this.month ? `${this.month}-01` : null;
    },
    loadingVariables() {
      return Boolean(this.loading | this.loadingLocal);
    }
  },
  watch: {
    companyId() {
      this.getBdgdVersion();
    },
    month() {
      this.getBdgdVersion();
    },
    bdgdVersion() {
      this.$emit('bdgdVersionSelected', this.bdgdVersion);
    }
  },
  methods: {
    getBdgdVersion() {
      if (!(this.companyId && this.data)) {
        return;
      }

      this.loadingLocal = true;
      BdgdVersionsService.getBdgdVersion(this.companyId, this.data)
        .then((version) => (this.bdgdVersion = version))
        .catch((err) => {
          console.log('Erro: ', err);
          this.$toast.error('Erro ao buscar a versão da BDGD.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loadingLocal = false));
    }
  }
};
</script>
